<template>
    <div>
        <el-divider></el-divider>
        <el-row>
            {{ $t("lang.日期") }}：<el-date-picker v-model="searchTime" @change="getList"></el-date-picker>
            <el-button type="primary" @click="print()" style="margin-left:10px;"> {{ $t("lang.打印") }}</el-button>
        </el-row>
        <el-col :span="16">
            <el-table ref="refElTable" :data="tableData" v-loading="tableLoading" highlight-current-row
                @current-change="loadDetail">
                <el-table-column type='selection' width="50"></el-table-column>
                <el-table-column :label="$t('lang.土类型')" prop="tlx"></el-table-column>
                <el-table-column :label="$t('lang.序列号')" prop="serialNumber" width="95"></el-table-column>
                <el-table-column :label="$t('lang.工程编号')" prop="gcbh"></el-table-column>
                <el-table-column :label="$t('lang.孔号')" prop="kh"></el-table-column>
                <el-table-column :label="$t('lang.试验深度')" prop="sysd"></el-table-column>
                <el-table-column :label="$t('lang.运行系数')" prop="yxxs"></el-table-column>
                <el-table-column :label="$t('lang.孔口高度')" prop="kkgd"></el-table-column>
                <el-table-column :label="$t('lang.地下水位')" prop="dxsw"></el-table-column>
                <el-table-column :label="$t('lang.试验时间')" prop="insertTime" width="150"></el-table-column>
                <el-table-column :label="$t('lang.操作')" width="135" align="center">
                    <template #default="scope">
                        <el-button-group>
                            <el-button type="success" size="mini" @click="exportTxt(scope.row)">{{ $t("lang.导出")
                            }}</el-button>
                            <el-button type="danger" size="mini" @click="del(scope.row)">{{ $t("lang.删除") }}</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="getList" background :total="total"
                :current-page.sync="currentPage"></el-pagination>
        </el-col>
        <el-col :span="8">
            <el-tabs stretch value="0">
                <el-tab-pane :label="$t('lang.锥尖阻力')" name="0">
                    <div id="jlct6Chart"></div>
                </el-tab-pane>
                <el-tab-pane :label="$t('lang.详细信息')" name="1">
                    <el-table :data="detailData" max-height="600" border>
                        <el-table-column :label="$t('lang.角度')" prop="jd" align="right"
                            :formatter="v => { return v.jd + '°'; }"></el-table-column>
                        <el-table-column :label="$t('lang.抗剪强度')" prop="kjqd" align="right"
                            :formatter="v => { return v.kjqd + 'kPa'; }"></el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-col>
    </div>
</template>
<script>
import HighCharts from "highcharts";
export default {
    data() {
        return {
            currentPage: 1,
            total: 0,
            tableLoading: false,
            searchTime: null,
            tableData: null,
            detailData: [],
        };
    },
    methods: {
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios
                .post("JLCT/GetMoShi6List", {
                    currentPage: that.currentPage,
                    total: that.total,
                    onlyDateTime: that.searchTime,
                })
                .then(function (response) {
                    that.tableLoading = false;
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                    if (that.tableData.length > 0) that.$refs.refElTable.setCurrentRow(that.tableData[0]);
                });
        },
        del(row) {
            let that = this;
            that.$confirm(that.$t("lang.确定要删除吗"))
                .then(function () {
                    that.axios.post("JLCT/DelJLCT6", { onlyInt: row.id }).then(() => {
                        that.getList();
                    });
                })
                .catch(function () { });
        },
        print() {
            let that = this;
            if (that.$refs.refElTable.selection.length == 2) {
                if (that.$refs.refElTable.selection[0].data == "null" || that.$refs.refElTable.selection[1].data == "null") {
                    that.$alert(that.$t("lang.存在空数据"));
                } else {
                    if (that.$refs.refElTable.selection[0].tlx == that.$t("lang.重塑土") && that.$refs.refElTable.selection[1].tlx == that.$t("lang.原状土")) {
                        open(process.env.VUE_APP_TurnUrl + "JLCT02/print?id1=" + that.$refs.refElTable.selection[1].id + "&id2=" + that.$refs.refElTable.selection[0].id) + "&&lang=" + window.localStorage.getItem("lang");
                    } else if (that.$refs.refElTable.selection[0].tlx == that.$t("lang.原状土") && that.$refs.refElTable.selection[1].tlx == that.$t("lang.重塑土")) {
                        open(process.env.VUE_APP_TurnUrl + "JLCT02/print?id1=" + that.$refs.refElTable.selection[0].id + "&id2=" + that.$refs.refElTable.selection[1].id + "&&lang=" + window.localStorage.getItem("lang"));
                    }
                }
            } else {
                that.$alert(that.$t("lang.请选择一条原状土和一条重塑土记录"));
            }
        },
        exportTxt(row) {
            open(process.env.VUE_APP_TurnUrl + "JLCT02/Txt?id=" + row.id);
        },
        loadDetail(row) {
            let that = this;
            var arr = eval(row.data);
            var arrData = [{ name: that.$t("lang.角度抗剪强度"), data: [] }];
            var arrData1 = [];
            if (row.data != "null") {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] != 0) {
                        arrData[0].data.push([i, arr[i]]);
                    }
                    arrData1.push({ jd: i, kjqd: arr[i] });
                }
            }
            that.loadMap(arrData, row.gcbh);
            that.detailData = arrData1;
        },
        loadMap(data, gcbh) {
            let that = this;
            HighCharts.chart("jlct6Chart", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                title: {
                    text: that.$t("lang.工程编号") + "(" + gcbh + ")",
                },
                tooltip: {
                    formatter: function () {
                        return that.$t("lang.抗剪强度") + "：<b>" + this.y + "</b>kPa<br />" + that.$t("lang.角度") + "：<b>" + this.x + "°</b>";
                    },
                },
                xAxis: {
                    title: {
                        text: that.$t("lang.角度") + "(°)",
                    },
                },
                yAxis: {
                    title: {
                        text: that.$t("lang.抗剪强度") + "(kPa)",
                    },
                },
                series: data,
                credits: {
                    enabled: false,
                },
            });
        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style></style>